import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  Result,
  Divider,
  Image,
  Space,
  Button,
  Popconfirm,
} from "antd";

import { LPPUrls } from "../../../LPPUrls";
import { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LoadingSpin } from "../../../components/common/Loading";
import { HContent } from "../../../components/common/HContent";
import useNotification from "antd/es/notification/useNotification";

const { Header } = Layout;
const { Title } = Typography;
const urlRegex = /(https?:\/\/[^\s]+)/;

interface RESPONSETYPE {
  id: number;
  title: string;
  content: any[];
  thumbnail: string[];
  views: number;
  createdAt: string;
}
export const DetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<RESPONSETYPE | null>(null);
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleDeleteNotation = async () => {
    setLoading(true);
    LPPAXIOS.delete(`/admin/notice/${id}`)
      .then((resolve) => {
        api.success({
          message: "공지사항을 정상적으로 삭제하였습니다.",
        });
        setTimeout(() => navigate(LPPUrls.Admin.Notation.Main.url()), 500);
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          api.error({
            message: "잘못된 요청입니다.",
          });
        }
      });
  };
  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<RESPONSETYPE>(
      `/admin/notice/${id}
    `
    )
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          setError(error);
        }
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (error !== null && data !== null)
    return <Result status={404} title={"404"} />;

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>공지사항</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Notation.Main.url()}>공지사항</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>공지사항 상세 페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Typography>
          <Typography.Title level={4}>{data?.title}</Typography.Title>
          <Space className="flex justify-between">
            <Space>
              <Typography.Text>작성일: {data?.createdAt}</Typography.Text>|
              <Typography.Text>조회수: {data?.views}</Typography.Text>
            </Space>
            <Space size="small">
              <Button
                type="text"
                onClick={() =>
                  navigate(LPPUrls.Admin.Notation.Update.url(id!), {
                    state: data,
                  })
                }
              >
                수정
              </Button>
              |
              <Popconfirm
                title="공지사항 삭제"
                description="공지사항을 삭제하시겠습니까?"
                onConfirm={handleDeleteNotation}
                okText="예"
                cancelText="아니오"
              >
                <Button type="text">삭제</Button>
              </Popconfirm>
            </Space>
          </Space>
          <Divider />
          {/* 이미지 5장 표시. */}
          <Space direction="vertical">
            {data?.thumbnail.map((item, key) => {
              return (
                <Image
                  key={`image-${key}`}
                  width={300}
                  src={`${process.env.REACT_APP_IMG_ENDPOINT}/${item}`}
                />
              );
            })}
          </Space>
          {/* <Image
            width={300}
            src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.thumbnail[0]}`}
          /> */}
          <Typography>
            {data?.content.map((item, index) => {
              if (item.type === "paragraph") {
                if (urlRegex.test(item.data.text)) {
                  return (
                    <a
                      key={`content-${index}`}
                      href={item.data.text}
                      target="_blank"
                    >
                      {item.data.text}
                    </a>
                  );
                } else {
                  if (item.data.text === "<br>") {
                    return <br key={index} />;
                  } else {
                    return (
                      <Typography.Paragraph
                        key={`content-${index}`}
                        className="whitespace-pre-line"
                      >
                        {item.data.text}
                      </Typography.Paragraph>
                    );
                  }
                }
              } else if (item.type === "linkTool") {
                return (
                  <a href={item.data.link} target="_blank">
                    {item.data.link}
                  </a>
                );
              }
              return null;
            })}
          </Typography>
        </Typography>
      </HContent>
    </>
  );
};
